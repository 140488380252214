import {ExtTranslationKeys} from './ext-translations-keys';

/**
 * MORE READABILITY ADVICE
 * Please maintain an alphabetical order in each section
 */

export const ESExtTranslations: ExtTranslationKeys = {
    //RGI_PORTAL_COMPONENTS Object
    // To be removed, not used anymore
    RGI_PORTAL_COMPONENTS: {
        ADD: 'Agregar',
        ADD_DOCUMENT: 'Añadir documento',
        CANCEL: 'Borrar',
        CODE: 'Código',
        CONFIRM: 'Confirmar',
        DELETE_DOC: 'Eliminar documento',
        DOCUMENTS_MANAGER: 'Gestión de documentos',
        DOCUMENT_TYPE: 'Tipo de documento',
        DOCUMENT_VALIDATION_MESSAGE: '¿Continuar con la validación del documento?',
        DOWNLOAD: 'Descargar',
        EDIT: 'Editar',
        FILE: 'Archivo',
        INPUT_DOCUMENTS: 'Documentos anexos',
        LOAD: 'Aceptar',
        NO: 'No',
        NOTE: 'Nota',
        NO_FILES: 'Sin archivos',
        NUMBER_OF_FILES_INSERTED: '{{ filesLength }} de {{ fileMax }} archivos insertados',
        OUTPUT_DOCUMENTS: 'Documentos de salida',
        SAVE: 'Ahorrar',
        STATE: 'Estado',
        UPDATE_FILE: 'Editar archivo',
        UPLOAD: 'Añadir documento',
        UPLOAD_NEW_DOCUMENT: 'Añadir documento',
        VALIDATION: 'Validación',
        VALIDATION_DOCUMENT: 'Validación de documentos',
        YES: 'Sí'
    },
    _ANAG_: {
        _MSG_: {
            _USER_ON_DIFFERENT_NODE_: 'Cliente no disponible. Utilizar Genernet'
        }
    },
    EXT_COMPONENTS: {
        _CLAIMS_HISTORY_: {
            _LABEL_: {
                _INITIAL_BM_LEVEL_: 'Nivel Bonus/Malus Entrada',
                _ACTUAL_BM_LEVEL_: 'Nivel Bonus/Malus Actual',
                _ORIGIN_: 'Origen',
                _HISTORY_MATRIX_: 'Matriz de historial sinistral',
                _MTPL_: 'Responsabilidad civil',
                _MOD_: 'Daños propios',
                _YEARS_: 'Años',
                _CLAIMS_: 'Siniestros',
                _CLAIMS_PERC: '% siniestralidad',
                _COEFF_: 'Coeficiente',
                _POLICY_NUMBER_: 'Número de póliza',
                _INCEPTION_DATE_: 'Fecha de emisión',
                _CANC_DATE_: 'Fecha de cancelación',
                _LICENSE_PLATE_: 'Matrícula',
                _VEHIC_TYPE_: 'Clase vehículo',
                _DATE_: 'Fecha',
                _MATERIAL_: 'Daños materiales',
                _BODILY_: 'Daños personales',
                _CALCULATION_DATE_: 'Fecha de cálculo'
            }
        },
        _DEEP_LINK_: {
            _NOT_FOUND_: 'ES_Not found',
            _CLOSE_: 'Cerrar'
        }
    },
    _CLAIMS_: {
        _CAUSE: 'Causa'
    },
    RGI_SHARED_COMPONENTS:{
        ADD: 'Agregar',
        ADD_DOCUMENT: 'Añadir documento',
        ARCHIVE: 'ARCHIVO',
        ARCHIVE_DOC: 'Archivar documento',
        CANCEL: 'Borrar',
        CLOSE: 'Cerrar',
        CODE: 'Código',
        CONFIRM: 'Confirmar',
        CREATED: 'Creado',
        DELETE_DOC: 'Eliminar documento',
        DELIVERY_DOC: 'Documento de entrega',
        DELIVERY_PARTY: 'Asunto de envío',
        DESCRIPTION: 'Descripción',
        DOWNLOAD_DOC: 'Descargar documento',
        DOCUMENTS_MANAGER: 'Gestión de documentos',
        DOCUMENT_TYPE: 'Tipo de documento',
        DOCUMENT_VALIDATION_MESSAGE: '¿Continuar con la validación del documento?',
        DOWNLOAD: 'Descargar',
        EDIT: 'Editar',
        FILE: 'Archivo',
        INPUT_DOCUMENTS: 'Documentos anexos',
        LOAD: 'Carga',
        NO: 'No',
        NOTE: 'Nota',
        NO_FILES: 'Sin archivos',
        NUMBER_OF_FILES_INSERTED: '{{ filesLength }} de {{ fileMax }} archivos insertados',
        OUTBOUND_DOC_TITLE: 'Documentos de póliza no. {{ policyNumber }} del movimiento {{ movementDesc }}',
        OUTBOUND_DOC_PROPOSAL_TITLE: 'Documentos de propuesta nr. {{ proposalNumber }} de movimiento {{ movementDesc }}',
        OUTPUT_DOCUMENTS: 'Documentos de salida',
        SAVE: 'Ahorrar',
        STATE: 'Estado',
        SIGN: 'Firmar',
        SEND: 'ENVIAR',
        TYPE: 'Tipo',
        TO_BE_GENERATED: 'para ser generado',
        UPDATE_FILE: 'Editar archivo',
        UPLOAD: 'Añadir documento',
        UPLOAD_NEW_DOCUMENT: 'Añadir documento',
        UPLOADED: 'Cargado',
        UPLOADING_USER: 'Cargando usuario',
        VALIDATION: 'Validación',
        VALIDATION_DOCUMENT: 'Validación de documentos',
        YES: 'Sí'
      },
      _MIC_:{
        _VEHICLE_ORIGIN_: 'Historial siniestral'
      }
}
